import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import "../styles/index.css"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <div id="indexContainer">
      <div id="indexHeroContainer">
        <div className="indexHeroInfo">
          <span className="indexHeroInfoWelcome">Welcome to</span><br/>
            {/* To display the image */}
            <span>
              <StaticImage
                src="../images/ao-logo-2.png"
                loading="eager"
                width={92}
                quality={92}
                formats={["auto", "webp", "avif"]}
                alt=""
                style={{ marginTop: `3px` }}
              />
            </span><br/>
            <span className="indexHeroInfoName">Assignment Operator</span><br/><br/>  
          <span className="indexHeroInfoSlogan">We assign true value to your assignment!</span>
          <p>We operate data-centric assignment based on state-of-the-art technology, programming, and data science principle.</p> 
          <Link to="/about/">More About Us</Link> &nbsp; &nbsp; &nbsp;
          <Link to="/contact/">Get In Touch</Link>
        </div>
      </div> <br/><br/><br/>

      <div id="indexServiceContainer">
        <div className="circleContainer">
          <div className="circle">
            <StaticImage
              src="../images/ao-logo-1.png"
              loading="eager"
              width={80}
              height={80}
              quality={82}
              formats={["auto", "webp", "avif"]}
              alt=""
            />
          </div>
          <div className="circleText">
            <span>We Offer Data Centric Services </span><br/><br/>
            <Link to="/service/">Our Services</Link>
          </div>
        </div> <br/><br/>
        <>
          <div className="indexServiceList">Data Consulting</div>
          <div className="indexServiceList">Data Preparation</div>
          <div className="indexServiceList">Machine Learning Model</div>
          <div className="indexServiceList">Deep Learning Model</div>
          <div className="indexServiceList">Predictive Analysis</div>
          <div className="indexServiceList">Image Analysis</div>
          <div className="indexServiceList">Optimization and Automation</div>
          <div className="indexServiceList">Implementation and Enhancement</div>
          <div className="indexServiceList">Training and Mentoring</div>
        </>
      </div>

    </div>
  </Layout>
)

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default IndexPage
